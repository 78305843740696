<template>
  <v-card>
    <v-card-title>
      <div>
        <div class="headline">Legacy Transfer</div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-btn
        x-small
        outlined
        color="primary"
        class="mb-3"
        @click="showFilters = !showFilters"
        >Filters
      </v-btn>
      <v-icon
          v-if="isFilterApplied"
          class="filter-applied-circle"
          size="12px"
          color="blue"
      >
        fas fa-circle
      </v-icon>
      <br />

      <div v-if="showFilters" class="mb-3" style="max-width: 300px">
        <v-text-field
          v-model="filters.legacy_unpaid_min"
          label="Min Legacy Unpaid"
          hint="Minimum legacy unpaid"
          color="primary"
          type="number"
          outlined
          persistent-hint
          small
          dense
          clearable
        ></v-text-field>
        <v-text-field
          v-model="filters.legacy_unpaid_max"
          label="Max Legacy Unpaid"
          hint="Maximum legacy unpaid"
          color="primary"
          type="number"
          outlined
          persistent-hint
          small
          dense
          clearable
        ></v-text-field>
        <v-text-field
          v-model="filters.avg_transactions_count_min"
          label="Min Average Transactions"
          hint="Minimum average transactions"
          color="primary"
          type="number"
          outlined
          persistent-hint
          small
          dense
          clearable
        ></v-text-field>
        <v-text-field
          v-model="filters.last_month_transactions_count_min"
          label="Min Last Month Transactions"
          hint="Minimum last month transactions"
          color="primary"
          type="number"
          outlined
          persistent-hint
          small
          dense
          clearable
        ></v-text-field>

        <v-btn color="primary" small class="float-right" @click="loadLegacyStatsByInfluencer">
          Search
        </v-btn>

        <div style="clear: both"></div>
      </div>

      <v-container fluid grid-list-md class="p-0 transfer-widgets mb-4">
        <v-layout wrap>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="legacyStats.legacy_total | local_numbers"
              subtitle="Legacy Total Owed"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="legacyStats.legacy_paid | local_numbers"
              subtitle="Legacy Paid"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="legacyStats.legacy_unpaid | local_numbers"
              subtitle="Legacy Unpaid"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="legacyStats.stripe_available | local_numbers"
              subtitle="Stripe Available"
              bordered
            ></stat-widget>
          </v-flex>
        </v-layout>
      </v-container>

      <legacy-transfer-data-table
        :items="legacyStatsByInfluencer"
        :loading="loading"
        :reload="loadLegacyStatsByInfluencer"
      ></legacy-transfer-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import local_numbers from "../../../helpers/filters/local_numbers";
import StatWidget from "../../widgets/StatWidget";
import LegacyTransferDataTable from "../data-tables/LegacyTransferDataTable";
import { isEmpty, mapValues } from 'lodash';

export default {
  components: {
    StatWidget,
    LegacyTransferDataTable,
  },
  filters: {
    local_numbers,
  },
  data: () => ({
    legacyStats: {},
    legacyStatsByInfluencer: [],
    loading: true,
    showFilters: false,
    filters: {
      legacy_unpaid_min: 1000,
      avg_transactions_count_min: 0,
      last_month_transactions_count_min: 0,
    },
  }),
  computed: {
    isFilterApplied() {
      return Object
          .values(this.filters)
          .some((value) => value !== undefined && value !== null && value !== '');
    },
  },
  methods: {
    ...mapActions("core", [
      "getLegacyStats",
      "getLegacyStatsByInfluencer",
    ]),
    loadLegacyStats() {
      this.getLegacyStats().then((response) => {
          this.legacyStats = response;
      });
    },
    loadLegacyStatsByInfluencer() {
      this.loading = true;

      const params = isEmpty(this.filters) ? null :
          mapValues(this.filters, (value) => {
            if (value === undefined || value === null || value === '') {
              return null;
            } else {
              return Number(value);
            }
          });
      this.getLegacyStatsByInfluencer(params).then((response) => {
          this.legacyStatsByInfluencer = response;
          this.loading = false;
      });
    },
  },
  created() {
    this.loadLegacyStats();
    this.loadLegacyStatsByInfluencer();
  },
};
</script>

<style lang="scss">
div.transfer-widgets {
  .v-application {
    div.display-1 {
      font-size: 1.7rem !important;
    }
  }
}
.filter-applied-circle {
  border-radius: 50%;
  margin-left: -8px;
  margin-top: -28px;
}
</style>
