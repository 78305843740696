<template>
  <div class="businesess-table-wrapper">
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="items.length"
      hide-default-footer
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.influencer_id">
            <td>
              <router-link
                :to="{ name: 'influencer', params: { id: item.influencer_uuid } }"
              >
                {{ item.influencer_name }}
              </router-link>
            </td>
            <td>
              {{ item.legacy_total | local_numbers }}
            </td>
            <td>
              {{ item.legacy_paid | local_numbers }}
            </td>
            <td>
              {{ item.legacy_unpaid | local_numbers }}
            </td>
            <td>
              {{ item.legacy_next_transfer | local_numbers }}
            </td>
            <td>
              {{ item.paid_installments | local_numbers }}
            </td>
            <td>
              {{ item.avg_transactions_count | local_numbers }}
            </td>
            <td>
              {{ item.last_month_transactions_count | local_numbers }}
            </td>
            <td>
              {{ item.performance | local_numbers }}
            </td>
            <td>
              <v-btn
                small
                color="primary"
                :disabled="isTransferDisabled(item)"
                @click="selectedInfluencer = item"
              >
                Transfer
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-dialog v-if="showDialog" v-model="showDialog" max-width="800px">
      <v-card>
        <v-card-title>
          {{ selectedInfluencer.influencer_name }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="amount"
                type="number"
                label="Amount"
                color="primary"
                outlined
                small
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="installments"
                type="number"
                label="Installments"
                color="primary"
                outlined
                small
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="total"
                type="number"
                label="Total (Calculated)"
                color="primary"
                outlined
                disabled
                small
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <i v-if="isDialogTransferDisabled" class="mr-2" style="color: orange">
            Total value is more than Legacy Unpaid ({{ selectedInfluencer.legacy_unpaid | local_numbers }} DKK)
          </i>
          <v-btn
            color="primary"
            :loading="transferLoading"
            :disabled="isDialogTransferDisabled"
            @click="transferClick()"
          >
            Transfer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import local_numbers from "../../../helpers/filters/local_numbers";

export default {
  filters: {
    local_numbers,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    reload: {
      type: Function,
      required: true,
    }
  },
  data: () => ({
    headers: [
      { text: "Influencer", value: "influencer_name", sortable: true },
      { text: "Legacy Total Owed, DKK", value: "legacy_total", sortable: true },
      { text: "Legacy Paid, DKK", value: "legacy_paid", sortable: true },
      { text: "Legacy Unpaid, DKK", value: "legacy_unpaid", sortable: true },
      { text: "Legacy Next Transfer, DKK", value: "legacy_next_transfer", sortable: true },
      { text: "Paid Installments", value: "paid_installments", sortable: true },
      { text: "Average Transactions", value: "avg_transactions_count", sortable: true },
      { text: "Last Month Transactions", value: "last_month_transactions_count", sortable: true },
      { text: "Performance Change, %", value: "performance", sortable: true },
    ],
    selectedInfluencer: null,
    amount: null,
    installments: 1,
    transferLoading: false,
  }),
  computed: {
    showDialog: {
      get() {
        return !!this.selectedInfluencer;
      },
      set() {
        this.selectedInfluencer = null;
      },
    },
    total() {
      return (this.amount === null || this.installments === null) ? null : +(this.amount * this.installments).toFixed(2);
    },
    isDialogTransferDisabled() {
      return this.total > this.selectedInfluencer.legacy_unpaid;
    },
  },
  methods: {
    ...mapActions("core", [
        "makeLegacyTransfer",
    ]),

    transferClick() {
      const params = {
        input: {
          influencer_id: this.selectedInfluencer.influencer_id,
          amount: this.total,
          installments: +this.installments,
        },
      };

      this.transferLoading = true;
      this.makeLegacyTransfer(params)
        .then(() => {
          this.transferLoading = false;
          this.setSnackSuccess("Success");
          this.showDialog = false;
          this.reload();
        })
        .catch((err) => {
          console.error(err);
          this.transferLoading = false;
          this.setSnackError("Something went wrong");
        });
    },
    isTransferDisabled(item) {
      return !item.legacy_next_transfer;
    },
  },
  watch: {
    selectedInfluencer() {
      if (!this.selectedInfluencer) {
        this.amount = null;
        this.installments = null;
      } else {
        this.amount = this.selectedInfluencer.legacy_next_transfer;
        this.installments = 1;
      }
    },
  },
};
</script>
